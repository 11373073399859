import React from "react"
import Layout from "../../layouts/default"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Button from "../../components/button"
import Divider from "../../components/divider"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import HyperCard from "../../components/hyper-card"
import Image from "../../components/image"
import Inline from "../../components/inline"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query AusstellungenQuery {
      turmzimmer: file(relativePath: { eq: "ausstellungen/turmzimmer.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 380, layout: CONSTRAINED)
        }
      }
      interaktion: file(relativePath: { eq: "ausstellungen/interaktion.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 380, layout: CONSTRAINED)
        }
      }
      postkarte: file(
        relativePath: { eq: "og_images/feuer-cover.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 700, height: 700, layout: CONSTRAINED)
        }
      }
      klimavitrine: file(
        relativePath: { eq: "ausstellungen/klimavitrine.jpg" }
      ) {
        ...hyperCardImage
      }
      vr: file(relativePath: { eq: "ausstellungen/vr-station.jpg" }) {
        ...hyperCardImage
      }
      featureImageLiebt: file(
        relativePath: { eq: "startseite/hoelderlin-liebt-ausstellung.jpg" }
      ) {
        ...hyperCardImage
      }
      featureImageHegelHoelderlin: file(
        relativePath: { eq: "startseite/idealismusschmiede.jpg" }
      ) {
        ...hyperCardImage
      }
      featureImageOrte: file(relativePath: { eq: "backdrops/orte.jpg" }) {
        ...hyperCardImage
      }
    }
  `)

  return (
    <Layout backdrop="ausstellungen">
      <Seo
        title="Ausstellungen"
        description="In einer Dauerausstellung und wechselnden Sonderausstellungen werden Hölderlins Tübinger Jahre, seine Werke und seine bis heute anhaltende Wirkung aus verschiedenen Perspektiven beleuchtet."
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Ausstellungen",
              link: "/ausstellungen",
            },
          ]}
        />
        <Stack space={[12, 24]}>
          <PageTitle>Ausstellungen</PageTitle>
          <Stack>
            <Heading as="h2" level={2}>
              Dauerausstellung
            </Heading>
            <Grid columns={2} space={"px"}>
              <Image
                image={data.turmzimmer.childImageSharp.gatsbyImageData}
                alt="Hölderlins Turmzimmer mit Schreibtisch"
                attribution="David Franck"
              />
              <Image
                image={data.interaktion.childImageSharp.gatsbyImageData}
                alt="Ein Besucher beim Verwenden einer unserer Medienstationen"
                attribution="David Franck"
              />
            </Grid>
            <Paragraph>
              Die Ausstellung widmet sich Hölderlins Leben im Turm (ab 1807),
              seinen Besuchern, der Schönheit seiner Turmgedichte und der
              Fürsorge, die er hier durch die Familie Zimmer empfing – und sie
              lässt die viel diskutierte Frage nach Hölderlins geistigem Zustand
              bewusst unbeantwortet. Sie beleuchtet aber auch Hölderlins
              Tübinger Studienjahre (1788–1793), in denen er erste Gedichte
              veröffentlichte und mit den späteren Philosophen Hegel und
              Schelling im Stift die revolutionären Ereignisse in Frankreich
              diskutierte.
            </Paragraph>

            <Inline space={6}>
              <Button to="/ausstellungen/dauerausstellung">
                Mehr Informationen
              </Button>
              <Button to="/dauerausstellung">
                Zur digitalen Dauerausstellung
              </Button>
            </Inline>
          </Stack>
          <Divider size={4} />

          <Stack>
            <Heading as="h2" level={2}>
              Sonderausstellungen
            </Heading>
            <Paragraph>
              Unsere wechselnden Sonderausstellungen zeigen verschiedene
              Perspektiven auf Hölderlin und seine Werke. Literarische
              Phänomene, Motive und Verfahren, individuelle Lektüreerfahrungen
              und Facetten des Dichters werden dabei zum Ausgangspunkt einer
              Recherche: Gemeinsam mit Kooperationspartnern aus der Kunst,
              Forschung und Gesellschaft erforschen wir Zugänge, sammeln
              Eindrücke und Sichtweisen und setzen produktive
              Auseinandersetzungen in Gang. Die Ergebnisse dieser Beschäftigung
              werden in der Sonderausstellung sichtbar gemacht und laden zum
              Mitdenken und Mitmachen ein.
            </Paragraph>
            <Paragraph>
              In begleitenden Veranstaltungen, Workshops und Projekten werden
              die Themen der Sonderausstellungen aufgegriffen, diskutiert,
              weitergedacht oder in die Praxis übertragen und so verschiedenen
              Zielgruppen zugänglich gemacht.
            </Paragraph>
            <Stack space={24}>
              <Stack space={6}>
                <Heading level={4}>Aktuelle Sonderausstellung</Heading>
                <HyperCard
                  title="Turm in Flammen!"
                  headingElement="h2"
                  alt="Karikatur, der Hölderlinturm brennt und eine Gruppe erstaunter Touristen fährt in einem Stocherkahn über den Neckar am Turm vorbei"
                  description="In der Nacht zum 15. Dezember 1875 brannte das Haus, in dem Friedrich Hölderlin seine zweite Lebenshälfte verbracht hat, nieder. Erst mit dem Wiederaufbau erhielt seine berühmte Gestalt und wurde zum vorrangigen Ort der Erinnerung an den Dichter. Durch einen spektakulären Archivfund kann nun erstmals belegt werden, dass das Haus einer Brandstiftung zum Opfer fiel. Die Ausstellung geht diesem Kriminalfall nach."
                  image={data.postkarte}
                  to="/sonderausstellungen/feuerteufel"
                  cta="Zur Digitalen Ausstellung"
                />
                <Heading level={4}>Vergangene Sonderausstellungen</Heading>
                <HyperCard
                  title="Hölderlin liebt…"
                  alt="Ein Blick in die Sonderausstellung. Im Raum stehen zwei runde Tische. An den Wänden sind bunte Illustrationen und der Ausstellungstitel ›Hölderlin liebt...‹ angebracht."
                  headingElement="h4"
                  image={data.featureImageLiebt}
                  description={
                    <span>
                      Der Dichter Friedrich Hölderlin liebt die Wörter. Die
                      schwierigen ganz besonders. Seine Gedichte sind voll von
                      ungewöhnlichen Wörtern. Das macht sie besonders, aber auch
                      schwer zu verstehen. <br />
                      <br />
                      Das soll sich nun ändern: <br />
                      Mit unserer Sonderausstellung über Friedrich Hölderlin in
                      Einfacher Sprache und Gebärdensprache.
                    </span>
                  }
                  cta="Zur digitalen Sonderausstellung"
                  to="/sonderausstellungen/hoelderlin-liebt"
                />
                <HyperCard
                  title="Idealismusschmiede in der Philosophen-WG"
                  headingElement="h4"
                  image={data.featureImageHegelHoelderlin}
                  description={`Hölderlin und Hegel verbrachten 5 gemeinsame Studienjahre in Tübingen und teilten sich zuweilen sogar ein Zimmer. Die Ausstellung geht den Anfängen der beiden Geistesgrößen nach, zeigt, wie sie sich gegenseitig in ihrem Denken beeinflusst haben und wie sie heute gelesen und betrachtet werden.`}
                  cta="Zur digitalen Sonderausstellung"
                  to="/sonderausstellungen/hegel-hoelderlin"
                />
                <HyperCard
                  title="Hölderlins Orte"
                  headingElement="h4"
                  image={data.featureImageOrte}
                  alt="Schwarzweiß-Fotografie des Zwingels, ein schmaler Weg am Hölderlinturm"
                  description={`In der Wanderausstellung ›Hölderlins Orte – Fotografien von Barbara Klemm‹ treffen Friedrich Hölderlins Verse und Landschaftsbeschreibungen auf die Bilder der Fotografin Barbara Klemm.`}
                  cta="Zur digitalen Sonderausstellung"
                  to="/sonderausstellungen/hoelderlins-orte"
                />
              </Stack>
            </Stack>
          </Stack>
          <Divider size={4} />
          <Stack>
            <Heading as="h2" level={2}>
              Aus dem Archiv geholt
            </Heading>
            <Grid columns={2}>
              <Image
                alt="Mehrere Begleithefte zur Reihe ›Aus dem Archiv geholt‹ auf der Klimavitrine"
                image={data.klimavitrine.childImageSharp.gatsbyImageData}
              />
              <Stack>
                <Paragraph>
                  In der Reihe ›Aus dem Archiv geholt‹ zeigt der Hölderlinturm
                  wechselnde Originale aus verschiedenen Sammlungen –
                  kommentiert von Gästen aus der Wissenschaft, Kunst und Kultur.
                </Paragraph>
                <Button to="/aus-dem-archiv-geholt/">Jetzt ansehen</Button>
              </Stack>
            </Grid>
          </Stack>
          <Divider size={4} />
          <Stack>
            <Heading as="h2" level={2}>
              VR-Station ›Hölderlins Echo‹
            </Heading>
            <Grid columns={2}>
              <Image
                alt="Eine Besucherin mit VR-Brille und Joysticks in den Händen."
                image={data.vr.childImageSharp.gatsbyImageData}
              />
              <Stack>
                <Paragraph>
                  Wie Hölderlins Turmzimmer wohl zu seiner Zeit ausgesehen haben
                  mag? Mit der Virtual-Reality ›Hölderlins Echo‹ im 2.
                  Obergeschoss unseres Museums könnt ihr in eine grafisch
                  animierte 3D-Rekonstruktion von Hölderlins einstigem
                  Turmzimmer eintreten.
                </Paragraph>
                <Button to="/ausstellungen/hoelderlins-echo">Mehr dazu</Button>
              </Stack>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default IndexPage
